<template>
	<div class="header-custom sticky">
		<v-app-bar app class="app-header" fixed flat elevate-on-scroll>
			<template v-slot:prepend>
				<v-app-bar-nav-icon width="30" class="d-lg-none d-sm-flex ml-auto drawer-icon text-white mr-0"
					icon="mdi-menu" @click.stop="drawer = !drawer" aria-label="Menu Button"> </v-app-bar-nav-icon>
			</template>

			<v-toolbar class="bg-transparent d-sm-flex d-flex align-center">
				<v-container class="fill-height">
					<!-- Logo -->
					<Logo />
					<span class="company"><strong>Diskubre Palawan</strong></span>
					<!-- Desktop view Navigation -->
					<div class="navigation d-sm-none ml-auto d-lg-block" v-bind:class="[drawer ? 'd-block' : '']">
						<ul class="d-flex navbar-nav" role="navigation">
							<li class="nav-item" v-for="(item, index) of links" :key="index" name="Navigation">
								<NuxtLink class="nav-link" :to="item.link"> {{ item.title }} </NuxtLink>
							</li>
						</ul>
					</div>

					<v-btn class="currency-display d-inline-flex" @click.stop="store?.toggleSelectDialog()"> {{
						currencyData?.flag
					}} {{ currencyData?.currencyCode }} </v-btn>
					<ClientOnly>
						<v-btn id="cart" class="d-inline-flex cart" v-if="cartSize > 0 && route.name != 'cart'" icon
							width="30" to="/cart">
							<v-badge color="error">
								<template #badge overlap> {{ cartSize }} </template>
								<v-icon color="white" dense> mdi-cart-outline </v-icon>
							</v-badge>
						</v-btn>
					</ClientOnly>
					<country-selector />
				</v-container>


			</v-toolbar>
		</v-app-bar>
	</div>
	<v-navigation-drawer v-model="drawer" temporary location="left" mobile disable-resize-watcher>
		<template v-slot:prepend>
			<v-list-item lines="two" prepend-avatar="/images/logos/logo.png" subtitle="Your travel partner"
				title="Diskubre Palawan"></v-list-item>
		</template>
		<v-divider></v-divider>
		<v-list density="compact" nav color="transparent" role="navigation">
			<v-list-item class="text-dark nav-link" v-for="(item, index) of links" :key="index" link :to="item.link"
				:title="item.title" :prepend-icon="item.icon" nav />
			<v-list-subheader>Contact Us</v-list-subheader>
			<v-list-item class="text-dark nav-link" v-for="(item, index) of contacts" :key="index" link
				:href="item.link" :title="item.title" :prepend-icon="item.icon" nav />
		</v-list>
		<v-divider></v-divider>
		<div class="d-flex justify-space-evenly mt-6 text-dark">
			<v-icon size="20" color="#607df9" icon="fa:fa-cc-visa" />
			<v-icon size="20" color="#607df9" icon="fa:fa-cc-mastercard" />
			<v-icon size="20" color="#607df9" icon="fa:fa-cc-amex" />
			<v-icon size="20" color="#607df9" icon="fa:fa-cc-jcb" />
		</div>
		<div class="d-flex justify-space-evenly mb-6 text-dark">
			<v-icon size="20" color="#607df9" icon="fa:fa-cc-discover" />
			<v-icon size="20" color="#607df9" icon="fa:fa-cc-diners-club" />
			<v-icon size="20" color="#607df9" icon="fa:fa-cc-paypal" />
			<v-icon size="20" color="#607df9" icon="fa:fa-money" />
		</div>
	</v-navigation-drawer>
</template>
<script setup>
const currencyStore = useCurrencyStore();
const { currencyData } = storeToRefs(currencyStore);
const store = useBookingStore();
const cartSize = toRef(() => store.getCartCount);
const route = useRoute();

const drawer = ref(false);
const links = ref([
	{
		title: 'Home',
		link: '/',
		icon: 'mdi-home',
	},
	{
		title: 'Transportation',
		link: '/transportation',
		icon: 'mdi-plane-car',
	},
	{
		title: 'Airport Transfers',
		link: '/airport-transfers',
		icon: 'mdi-plane-car',
	},
	{
		title: 'Tours',
		link: '/tours',
		icon: 'mdi-umbrella-beach',
	},
]);

const contacts = ref([
	{
		title: 'Send us an email',
		link: 'mailto:admin@diskubrepalawan.com',
		icon: 'mdi-gmail',
		color: '#c71610',
	},
	{
		title: 'Contact us on WhatsApp',
		link: 'https://api.whatsapp.com/send?phone=639632160905',
		icon: 'mdi-whatsapp',
		color: '#25d366',
	},
	{
		title: 'Contact us on Facebook',
		link: 'https://m.me/diskubretravel',
		icon: 'mdi-facebook-messenger',
		color: '#168AFF',
	},
]);
</script>
<style scoped>
.company {
	margin-left: 10px;
	font-size: 1rem;
	color: white;
}

.navigation {
	margin-right: 20px;
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1028;
}

.cart {
	position: absolute;
	right: 50px;
	z-index: 1029;
}

.currency-display {
	color: white;
	font-size: 0.9rem;
	position: absolute;
	right: 80px;
	z-index: 1029;
}

.currency {
	margin-left: 20px;
	position: absolute;
	right: 80px;
}

.v-navigation-drawer {
	z-index: 1030 !important;
}
</style>
